$(document).ready(function () {

    // --------------  Products Index page ---------------
    var product_matches_show_grid_page = document.querySelector('#product_matches_show_grid');

    if (product_matches_show_grid_page != undefined) {

        // console.log('product_matches_show_grid_page');

        var dictionary = {
            ru: {
                manufacturer_name_en: "Производитель",
                model: "Модель",
                series: "Серия",
                year_from: "От",
                year_to: "До",
            },
            en: {
                manufacturer_name_en: "Manufacturer",
                model: "Model",
                series: "Series",
                year_from: "Year from",
                year_to: "Year to",
            }
        };

        function getColumnNameByCulture(culture, word) {
            // console.log(user_report_dictionary[culture][word])
            return dictionary[culture][word]
        }

        function resize_window() {
            $("#product_matches_show_grid").children(".k-grid-content").height($(window).height() - 400);
        }

        $(window).resize(function () {
            resize_window();
        });

        let product_matches_show_grid = $("#product_matches_show_grid").kendoGrid({
            dataSource: {
                sort: [
                    {field: "manufacturer_name_en", dir: "asc"},
                    {field: "series", dir: "asc"},
                ],
                data: gon.equipment_list,
                schema: {
                    model: {
                        fields: {
                            id: {type: "string"},
                            manufacturer_name_en: {type: "string"},
                            model: {type: "string"},
                            series: {type: "string"},
                            year_from: {type: "string"},
                            year_to: {type: "string"}
                        }
                    }
                },
                pageSize: 30
            },
            dataBound: function (e) {
                resize_window();
                // products_table_row_coloring();
            },
            scrollable: true,
            sortable: {
                mode: "mixed",
                allowUnsort: true,
                showIndexes: true
            },
            resizable: true,
            pageable: {
                input: true,
                numeric: false
            },
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    title: getColumnNameByCulture(gon.locale, 'manufacturer_name_en'),
                    field: "manufacturer_name_en",
                    filterable: {
                        cell: {
                            operator: "contains",
                            // suggestionOperator: "contains"
                            showOperators: false
                        }
                    },
                    width: 170
                },
                {
                    title: getColumnNameByCulture(gon.locale, 'series'),
                    field: "series",
                    filterable: {
                        cell: {
                            operator: "contains",
                            // suggestionOperator: "contains"
                            showOperators: false
                        }
                    },
                    width: 150
                },
                {
                    title: getColumnNameByCulture(gon.locale, 'model'),
                    field: "model",
                    filterable: {
                        cell: {
                            operator: "contains",
                            // suggestionOperator: "contains"
                            showOperators: false
                        }
                    }
                    // width: 150
                },
                {
                    title: getColumnNameByCulture(gon.locale, 'year_from'),
                    field: "year_from",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 100
                },
                {
                    title: getColumnNameByCulture(gon.locale, 'year_to'),
                    field: "year_to",
                    filterable: {
                        cell: {
                            showOperators: false
                        }
                    },
                    width: 100
                }
            ]
        }).data("kendoGrid");


    }

})