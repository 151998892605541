$(document).ready(function () {

    // Organiation Index page
    var organizations_table = document.querySelector('#organizations_table');

    if (organizations_table != undefined) {

        var organization_dictionary = {
            ru: {
                created_at: 'Дата создания',
                contract_number: 'Номер договора',
                contract_signed: 'Договор подписан',
                short_w_opf_name: 'Название организации',
                inn: 'ИНН',
                products_count: 'Продуктов',
                services: 'Сервисов',
                active_services: 'Активных сервисов',
                active_service_names: 'Сервисы',
                active_until_service: 'Активны до',
                region_names: 'Регионы',
                category_group_names: 'Группы категорий',
                is_liquids_library: "Библиотечные"
            },
            en: {
                created_at: 'Created at',
                contract_number: 'Contract number',
                contract_signed: 'Contract signed',
                short_w_opf_name: 'Name',
                inn: 'INN',
                products_count: 'Products',
                services: 'Services',
                active_services: 'Active services',
                active_service_names: 'Active services',
                active_until_service: 'Active until',
                region_names: 'Regions',
                category_group_names: 'Category groups',
                is_liquids_library: "Is library"
            }
        };

        function getOrganizationColumnNameByCulture(culture, word) {
            return organization_dictionary[culture][word]
        }


        function organizations_table_resize() {
            //grid auto heigh
            $("#organizations_table").children(".k-grid-content").height($(window).height() - 240);

        }

        $(window).resize(function () {
            organizations_table_resize();
        });


        function organizations_table_row_coloring() {
            var grid = $("#organizations_table").data("kendoGrid");
            var data = grid.dataSource.data();

            $.each(data, function (i, row) {

                if (row.contract_signed == true) {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#c5ebc7"); //green (Подписан)
                } else {
                    $('tr[data-uid="' + row.uid + '"] ').css("background-color", "#eca6b0"); //red (Не подписан)
                }
            });
        }


        var organizations_table_grid = $("#organizations_table").kendoGrid({

            dataSource: {

                // sort: {field: "invoice_date", dir: "desc"},

                transport: {
                    read: "/organizations.json"
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            contract_number: {type: "string"},
                            contract_signed: {type: "boolean"},
                            // contract_uploaded: {type: "boolean"},
                            short_w_opf_name: {type: "string"},
                            inn: {type: "string"},
                            services: {type: "string"},
                            active_services: {type: "string"},
                            active_service_names: {type: "string"},
                            active_until_service: {type: "date"},
                            products_count: {type: "string"},
                            region_names: {type: "string"},
                            category_group_names: {type: "string"},
                            is_liquids_library: {type: "boolean"}
                        }
                    }
                },

                total: function (response) {
                    return response[0].TotalRecords;
                },
                pageSize: 100,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true
            },

            // change: onSelectInvoice,
            dataBound: function (e) {
                organizations_table_resize();

                organizations_table_row_coloring();
            },
            sortable: true,
            reorderable: true,
            resizable: true,
            pageable: {
                refresh: true,
                // pageSizes: true,
                pageSizes: [30, 50, 100, 250], //, "all"
                buttonCount: 10,

                messages: {
                    itemsPerPage: "",
                    display: "{0} - {1} из {2} организаций"
                }
            },

            columnMenu: {
                sortable: false,
                filterable: false
            },
            selectable: "true",
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-green-600 bg-gray-100 hover:bg-gray-200" style="font-size: 11px;" href="/#= gon.locale #/organizations/#=id#/product_export.xlsx"><i class="fa-solid fa-file-excel"></i></i></a></div>',
                    width: 40
                },
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-gray-100 hover:bg-gray-200" style="font-size: 11px;" href="/#= gon.locale #/organizations/#=id#/">Show</i></a></div>',
                    width: 60,
                    hidden: false
                },
                {
                    template: '<div align="center"><a class="inline-flex items-center px-2 py-0.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-yellow-100 hover:bg-yellow-200" style="font-size: 11px;" href="/#= gon.locale #/organizations/#=id#/edit">Edit</i></a></div>',
                    width: 60,
                    hidden: false
                },
                {
                    title: getOrganizationColumnNameByCulture(gon.locale, 'contract_number'),
                    field: "contract_number",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                    width: 100
                },
                // {
                //     title: getOrganizationColumnNameByCulture(gon.locale, 'contract_uploaded'),
                //     field: "contract_uploaded",
                //     filterable: {
                //         toolbar: false,
                //         cell: {
                //             showOperators: false,
                //             enabled: true
                //         },
                //         messages: {isTrue: ' Y ', isFalse: " N "}
                //     },
                //
                //     template: '<div align="center"># if (contract_uploaded == true) {# <span style="color: green"><i class="fas fa-check"></i></span> # } else { # <span style="color: red"><i class="fas fa-times-circle"></i></span> # } #</div>',
                //     width: 100
                // },

                {
                    title: getOrganizationColumnNameByCulture(gon.locale, 'contract_signed'),
                    field: "contract_signed",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        },
                        messages: {isTrue: ' Y ', isFalse: " N "}
                    },
                    template: '<div align="center"># if (contract_signed == true) {# <span style="color: green"><i class="fas fa-check"></i></span> # } else { # <span style="color: red"><i class="fas fa-times-circle"></i></span> # } #</div>',
                    width: 70
                },
                {
                    title: getOrganizationColumnNameByCulture(gon.locale, 'short_w_opf_name'),
                    field: "short_w_opf_name",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                },
                {
                    title: getOrganizationColumnNameByCulture(gon.locale, 'inn'),
                    field: "inn",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                },
                {
                    title: getOrganizationColumnNameByCulture(gon.locale, 'products_count'),
                    field: "products_count",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                    width: 100
                },
                {
                    title: getOrganizationColumnNameByCulture(gon.locale, 'services'),
                    field: "services",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                    width: 100
                },
                {
                    title: getOrganizationColumnNameByCulture(gon.locale, 'active_services'),
                    field: "active_services",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        }
                    },
                    width: 100
                },
                {
                    title: getOrganizationColumnNameByCulture(gon.locale, 'active_service_names'),
                    field: "active_service_names",
                    filterable: false,
                    sortable: false
                },

                {
                    title: getOrganizationColumnNameByCulture(gon.locale, 'active_until_service'),
                    field: "active_until_service",
                    format: "{0:dd.MM.yyyy | HH:mm:ss}",
                    type: "date",
                    filterable: false,
                    sortable: false
                },
                {
                    title: getOrganizationColumnNameByCulture(gon.locale, 'region_names'),
                    field: "region_names",
                    filterable: false,
                    sortable: false
                },
                {
                    title: getOrganizationColumnNameByCulture(gon.locale, 'category_group_names'),
                    field: "category_group_names",
                    filterable: false,
                    sortable: false
                },
                {
                    title: getOrganizationColumnNameByCulture(gon.locale, 'is_liquids_library'),
                    field: "is_liquids_library",
                    filterable: {
                        toolbar: false,
                        cell: {
                            showOperators: false,
                            enabled: true
                        },
                        messages: {isTrue: ' Y ', isFalse: " N "}
                    },

                    template: '<div align="center"># if (is_liquids_library == true) {# <span style="color: green"><i class="fas fa-check"></i></span> # } else { # <span style="color: red"><i class="fas fa-times-circle"></i></span> # } #</div>',
                    width: 100
                },


            ]
        }).data("kendoGrid");

    }

})