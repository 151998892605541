var selectors_category_group_id = null;
var selectors_manufacturer_id = null;
var selectors_product_mid = null;

$(document).ready(function () {

    // --------------  Series Index page ---------------
    var product_matches_selectors_page = document.querySelector('#product_matches_selectors_search');

    if (product_matches_selectors_page != undefined) {

        // reload page to refresh token after < 30min
        function reload_page() {
            console.log('reload!');
            location.reload();
        }

        setTimeout(reload_page, 1700000);

        // hide selectors if service is not available now and show warning
        if (gon.not_available == true) {

            $('#service_available').addClass('hidden');
            $('#service_not_available').removeClass('hidden');

        } else {
            $('#service_available').removeClass('hidden');
        }


        function on_select_product(e) {
            let dataItem = this.dataItem(e.item);
            selectors_product_mid = dataItem.mid;
            // console.log(selectors_product_mid);

            // if ((dataItem) && (dataItem.mid)) {
            //     window.location = '/' + gon.locale + "/analytics/product_matches/0?mid=" + dataItem.mid + "&session=" + gon.req_tk;
            // }
        }

        function on_select_category_group(e) {
            let dataItem = this.dataItem(e.item);
            selectors_category_group_id = dataItem.id;
            // console.log(selectors_category_group_id);
        }

        function on_select_equipment_manufacturer(e) {
            let dataItem = this.dataItem(e.item);
            if (dataItem.id != null) {
                selectors_manufacturer_id = dataItem.id;
                // console.log(selectors_manufacturer_id);
            }
        }

        $("#product_matches_selectors_search").kendoDropDownList({
            filter: "startswith",
            dataTextField: "name",
            dataValueField: "id",
            height: 350,

            autoBind: false,
            // enable: false,
            // optionLabel: {
            //     name_en: '',
            //     id: 0
            // },
            optionLabel: '',

            template: '<div class="flex flex-row items-center"><div class="rounded-md bg-green-50 px-2 py-0.5 mx-2">#= data.product_manufacturer_name_en #</div><div class="text-gray-900 mr-4"> #= data.name # </div></div>',
            // template: '<div class="flex flex-row items-center"><div class="text-gray-900 mr-4">#= data.manufacturer_name # #= data.model # </div># if ((false) && (data.generations)) {#<div class="rounded-md bg-blue-50 px-2 py-0.5 mx-2">#: data.generations #</div>#}#  # if (data.fuel_type_name) {# <div>#: data.fuel_type_name #</div>#}#  # if (data.engine_output_kw){# <div class="text-green-700 rounded-md bg-green-100 px-2 py-0.5 mx-2">#: data.engine_output_kw # #: gon.kw #</div>  <div class="text-yellow-700 rounded-md bg-yellow-100 px-2 py-0.5 mx-2">#: data.engine_output_hp # #: gon.hp #</div> #}#  # if ((data.year_from) || (data.year_to)) {# <div class="text-gray-600 px-2 py-0.5 rounded-md bg-gray-100 mx-2"># if (data.year_from) {# #= data.year_from # #}# - # if (data.year_to){#  #= data.year_to # #}#</div>#}#</div>',

            // valueTemplate: '#= data.template_image # &nbsp;&nbsp; #: data.name_en #',
            select: on_select_product,
            dataSource: {
                // sort: {field: "model", dir: "asc"},
                serverFiltering: true,
                transport: {
                    read: {
                        dataType: "json",
                        crossDomain: true,
                        withCredentials: true,
                        url: function () {
                            return '/' + gon.locale + "/analytics/product_matches/search.json";
                        },
                        // data: {
                        //     "filter": {
                        //         "logic": "and",
                        //         "custom_filters": {
                        //             "0": {
                        //                 "value": 'genuine',
                        //                 "field": "manufacturer",
                        //                 "operator": "startswith",
                        //                 "ignoreCase": "true"
                        //             }
                        //         }
                        //     }
                        // },
                        // beforeSend: function (req) {
                        //     req.setRequestHeader("Authorization", atob(gon.req_tk));
                        // },

                    },
                    // parameterMap: function (options, type) {
                    //     console.log(kendo.stringify(options));
                    //     return kendo.stringify(options)
                    // }
                },
                serverPaging: true,
                pageSize: 100,
                schema: {
                    total: "total",
                    data: "results",
                    // id: "mid",
                    model: {
                        fields: {
                            mid: {type: "string"},
                            name: {type: "string"},
                            product_manufacturer_name_en: {type: "string"},
                        }
                    }
                },
            }
        });


        let selectors_category_group = $("#selectors_category_group").kendoDropDownList({
            filter: "startswith",
            dataTextField: "name",
            dataValueField: "id",
            height: 350,
            // autoBind: false,
            // optionLabel: {
            //     name_en: '',
            //     id: 0
            // },
            // placeholder: "Select...",
            optionLabel: '',
            // template: '<span class="flex flex-row k-state-default text-xl"><div class="text-gray-500 mr-4">#= data.template_image # </div><div> #: data.name #</div></span>',
            // template: template_category_group,
            // valueTemplate: template_value_category_group, // '#= data.template_image # &nbsp;&nbsp; #: data.name #',
            select: on_select_category_group,

            // select: on_select_equipment_manufacturer,
            // change: on_select_equipment_manufacturer,

            dataBound: function (e) {
                e.sender.select("tr:eq(1)");
                // console.log("select", e);
            },
            // change: on_select_category_group,
            dataSource: {
                sort: {field: "priority", dir: "desc"},
                serverFiltering: true,
                transport: {
                    read: {
                        dataType: "json",
                        crossDomain: true,
                        withCredentials: true,
                        url: "/" + gon.locale + "/api/v1/category_groups",
                        beforeSend: function (req) {
                            req.setRequestHeader("Authorization", atob(gon.req_tk));
                            // req.setRequestHeader("Authorization", gon.req_tk);
                        }
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name: {type: "string"},
                            template_image: {type: "string"},
                        }
                    }
                },
            }
        }).data("kendoDropDownList");

        // var selectors_manufacturers = $("#selectors_manufacturers").kendoDropDownList({
        //     filter: "startswith",
        //     dataTextField: "name",
        //     dataValueField: "id",
        //     height: 350,
        //     cascadeFrom: "selectors_category_group",
        //     autoBind: false,
        //     // enable: false,
        //     // optionLabel: {
        //     //     name_en: '',
        //     //     id: 0
        //     // },
        //     // optionLabel: gon.option_label_manufacturer,
        //     select: on_select_equipment_manufacturer,
        //     change: on_select_equipment_manufacturer,
        //     template: '<span class="flex flex-row k-state-default items-center"># if (data.logo_image) {#<img class="mr-4" src="#: data.logo_image #">#} else {# <div class="mr-12"></div> #}# #: data.name #</span>',
        //     valueTemplate: '<span class="flex flex-row k-state-default mr-2"># if (data.logo_image) {#<img class="mr-2" src="#: data.logo_image #">#}#  #: data.name #</span>',
        //
        //     // select: on_select_manufacturer,
        //     dataSource: {
        //         sort: {field: "name", dir: "asc"},
        //         serverFiltering: true,
        //         transport: {
        //             read: {
        //                 dataType: "json",
        //                 crossDomain: true,
        //                 withCredentials: true,
        //                 url: function () {
        //                     return "/" + gon.locale + "/api/v1/category_groups/" + selectors_category_group_id + "/manufacturers";
        //                 },
        //                 beforeSend: function (req) {
        //                     req.setRequestHeader("Authorization", atob(gon.req_tk));
        //                 }
        //             }
        //         },
        //         schema: {
        //             total: "total",
        //             data: "results",
        //             id: "id",
        //             model: {
        //                 fields: {
        //                     id: {type: "integer"},
        //                     name: {type: "string"},
        //                     logo_image: {type: "string"}
        //                 }
        //             }
        //         },
        //     }
        // }).data("kendoDropDownList");


        $('#analyse_product_match').click(function (event) {
            event.preventDefault();
            event.stopPropagation();

            if ((selectors_category_group_id) && (selectors_product_mid)) { //&& (selectors_manufacturer_id)
                $('#analyse_product_match').addClass('hidden');
                $('#analyse_product_match_spin').removeClass('hidden');

                window.location = '/' + gon.locale + "/analytics/product_matches/0?mid=" + selectors_product_mid + '&g=' + selectors_category_group_id + "&session=" + gon.req_tk;
            }
        })


    }

});