$(document).ready(function () {

    // Organiation Edit page
    var organization_inn_field = document.querySelector('#organization_inn');

    if (organization_inn_field != undefined) {

        $("#organization_component_type_ids").kendoMultiSelect({
            placeholder: "Please Select a Component Type to Hide it",
            dataTextField: "name_en",
            dataValueField: "id",
            autoBind: false,
            height: 500,
            value: gon.component_types_to_hide,
            dataSource: {
                serverFiltering: true,
                sort: {field: "name_en", dir: "asc"},
                transport: {
                    read: {
                        url: "/component_types.json",
                        data: {
                            filter: {
                                logic: "and",
                                filters: [
                                    {field: "paging_enable", operator: "eq", value: true}
                                ]
                            }
                        }
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name_en: {type: "string"},
                            name_ru: {type: "string"},
                        }
                    }
                }
            }
        });

        $("#organization_category_group_ids").kendoMultiSelect({
            placeholder: "Please Select a Group of category",
            dataTextField: "name_en",
            dataValueField: "id",
            autoBind: false,
            height: 500,
            value: gon.category_groups,
            dataSource: {
                serverFiltering: true,
                sort: {field: "name_en", dir: "asc"},
                transport: {
                    read: {
                        url: "/category_groups.json",
                        data: {
                            filter: {
                                logic: "and",
                                filters: [
                                    {field: "paging_enable", operator: "eq", value: true}
                                ]
                            }
                        }
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name_en: {type: "string"},
                            name_ru: {type: "string"},
                        }
                    }
                }
            }
        });

        $("#organization_region_ids").kendoMultiSelect({
            placeholder: "Please Select a Region",
            dataTextField: "name_en",
            dataValueField: "id",
            autoBind: false,
            height: 500,
            value: gon.regions,
            dataSource: {
                serverFiltering: true,
                sort: {field: "name_en", dir: "asc"},
                transport: {
                    read: {
                        url: "/regions.json",
                        data: {
                            filter: {
                                logic: "and",
                                filters: [
                                    {field: "paging_enable", operator: "eq", value: true}
                                ]
                            }
                        }
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name_en: {type: "string"},
                            name_ru: {type: "string"},
                        }
                    }
                }
            }
        });


        $("#organization_manufacturer_ids").kendoMultiSelect({
            placeholder: "Please Select the Manufacturers to Hide it",
            dataTextField: "name_en",
            dataValueField: "id",
            autoBind: false,
            height: 500,
            filter: "contains",
            value: gon.manufacturers_to_hide,
            dataSource: {
                pageSize: 100,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,

                sort: {field: "name_en", dir: "asc"},
                transport: {
                    read: {
                        url: "/manufacturers.json",
                        data: {
                            filter: {
                                logic: "and",
                                filters: [
                                    {field: "paging_enable", operator: "eq", value: true}
                                ]
                            },
                            custom_filters: [
                                {field: "is_active", operator: "eq", value: true}
                            ]
                        }
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name_en: {type: "string"},
                            name_ru: {type: "string"},
                        }
                    }
                }
            }
        });





        // search by inn
        var selected_inn = null;
        var selected_name_full_with_opf = null;
        var selected_name_short_with_opf = null;
        var selected_kpp = null;
        var selected_address = null;
        var selected_okved = null;
        var selected_ogrn = null;
        var selected_oktmo = null;
        var selected_okato = null;
        var selected_okpo = null;
        var selected_city = null;

        // search by bik
        var selected_bik = null;
        var selected_name_short = null;
        var selected_correspondent_account = null;
        var selected_bank_name = null;

        function activate_button_fill(button_id, activate) {
            if (activate) {
                $(button_id).attr("disabled", false);
                $(button_id).addClass("text-gray-700 bg-gray-50 hover:text-gray-500")
                $(button_id).removeClass("bg-gray-100 text-white opacity-80 cursor-not-allowed");
            } else {
                $(button_id).attr("disabled", true);
                $(button_id).removeClass("text-gray-700 bg-gray-50")
                $(button_id).addClass("bg-gray-100 text-white opacity-80 cursor-not-allowed");
            }
        }

        activate_button_fill("#suggestions_fill_by_inn", false);
        $('#suggestions_fill_by_inn').click(function (e) {
            e.preventDefault();
            // console.log('click');

            if (selected_inn) {

                $('#organization_full_w_opf_name').val(selected_name_full_with_opf);
                $('#organization_short_w_opf_name').val(selected_name_short_with_opf);
                $('#organization_kpp').val(selected_kpp);

                $('#organization_mailing_address').val(selected_address);
                $('#organization_legal_address').val(selected_address);
                $('#organization_actual_production_address').val(selected_address);
                $('#organization_city').val(selected_city);

                $('#organization_okved').val(selected_okved);
                $('#organization_ogrn').val(selected_ogrn);
                $('#organization_oktmo').val(selected_oktmo);
                $('#organization_okato').val(selected_okato);
                $('#organization_okpo').val(selected_okpo);

            }

        });

        activate_button_fill("#suggestions_fill_by_bik", false);
        $('#suggestions_fill_by_bik').click(function (e) {
            e.preventDefault();
            // console.log('click');

            if (selected_bik) {
                $('#organization_ks').val(selected_correspondent_account);
                $('#organization_bank_name').val(selected_bank_name);
            }

        });


        $("#organization_inn").kendoAutoComplete({
            dataTextField: "inn",
            filter: "contains",
            minLength: 10,
            template: '<span class="k-state-default"><b>#: data.inn #</b> &nbsp; <small>#: data.name_short_with_opf #</small></span>',
            select: function (e) {
                var dataItem = this.dataItem(e.item);

                if (dataItem) {

                    selected_inn = dataItem.inn;
                    selected_name_full_with_opf = dataItem.name_full_with_opf;
                    selected_name_short_with_opf = dataItem.name_short_with_opf;
                    selected_kpp = dataItem.kpp;
                    selected_address = dataItem.address;
                    selected_okved = dataItem.okved;
                    selected_ogrn = dataItem.ogrn;
                    selected_oktmo = dataItem.oktmo;
                    selected_okato = dataItem.okato;
                    selected_okpo = dataItem.okpo;
                    selected_city = dataItem.city;

                    // console.log(dataItem.inn);
                    // console.log(dataItem.name_full_with_opf);
                    // console.log(dataItem.name_short_with_opf);
                    // console.log(dataItem.kpp);
                    // console.log(dataItem.address);
                    // console.log(dataItem.okved);
                    // console.log(dataItem.ogrn);
                    // console.log(dataItem.oktmo);
                    // console.log(dataItem.okato);
                    // console.log(dataItem.okpo);
                    // console.log(dataItem.city);

                    activate_button_fill("#suggestions_fill_by_inn", true);
                    // console.log('enable');
                } else {
                    activate_button_fill("#suggestions_fill_by_inn", false);
                    // console.log('disable');
                }


            },
            dataSource: {
                serverFiltering: true,
                transport: {
                    read: "/suggestions.json",
                    data: {
                        filter: {field: "by_inn", operator: "eq", value: true}
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    model: {
                        fields: {
                            inn: {type: "string"},
                            name_full_with_opf: {type: "string"},
                            name_short_with_opf: {type: "string"},
                            kpp: {type: "string"},
                            address: {type: "string"},
                            okved: {type: "string"},
                            ogrn: {type: "string"},
                            oktmo: {type: "string"},
                            okato: {type: "string"},
                            okpo: {type: "string"},
                            city: {type: "string"},
                        }
                    }
                }
            }
        });


        $("#organization_bik").kendoAutoComplete({
            dataTextField: "bik",
            filter: "contains",
            minLength: 3,
            template: '<span class="k-state-default"><b>#: data.bik #</b> &nbsp; <small>#: data.bank_name #</small></span>',
            select: function (e) {
                var dataItem = this.dataItem(e.item);

                if (dataItem) {
                    activate_button_fill("#suggestions_fill_by_bik", true);
                    // console.log('enable');
                } else {
                    activate_button_fill("#suggestions_fill_by_bik", false);
                    // console.log('disable');
                }

                selected_bik = dataItem.bik;
                selected_name_short = dataItem.name_short;
                selected_correspondent_account = dataItem.correspondent_account;
                selected_bank_name = dataItem.bank_name;

                // console.log(dataItem.bik);
                // console.log(dataItem.name_short);
                // console.log(dataItem.correspondent_account);
            },
            dataSource: {
                serverFiltering: true,
                transport: {
                    read: "/suggestions.json",
                },
                schema: {
                    total: "total",
                    data: "results",
                    model: {
                        fields: {
                            bik: {type: "string"},
                            name_short: {type: "string"},
                            correspondent_account: {type: "string"},
                            bank_name: {type: "string"}
                        }
                    }
                }
            }
        });


        $("#organization_tel").kendoMaskedTextBox({
            mask: "+7 (000) 000-0000"
        });

        $("#organization_fax").kendoMaskedTextBox({
            mask: "+7 (000) 000-0000"
        });



        $("#organization_product_manufacturers_for_analytic_ids").kendoMultiSelect({
            placeholder: "Please Select the Product Manufacturers for an analytics",
            dataTextField: "name_en",
            dataValueField: "id",
            autoBind: false,
            height: 500,
            filter: "contains",
            value: gon.product_manufacturers_for_analytics,
            dataSource: {
                pageSize: 100,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,

                sort: {field: "name_en", dir: "asc"},
                transport: {
                    read: {
                        url: "/product_manufacturers.json",
                        data: {
                            filter: {
                                logic: "and",
                                filters: [
                                    {field: "paging_enable", operator: "eq", value: true}
                                ],
                                custom_filters: [
                                    {field: "is_liquids_library", operator: "eq", value: true}
                                ]
                            },

                        }
                    }
                },
                schema: {
                    total: "total",
                    data: "results",
                    id: "id",
                    model: {
                        fields: {
                            id: {type: "integer"},
                            name_en: {type: "string"},
                            name_ru: {type: "string"},
                        }
                    }
                }
            }
        });

    }

})